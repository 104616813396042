import React from 'react'
import './navbar.scss';
import {Fade } from 'react-awesome-reveal';
import {NavLink} from 'react-router-dom';
export default function Navbar() {
    return (
        <nav className="navbar">
           <a href='/' className='site-title'>Chris Dickson <small className='subtitle'>Art & Design</small></a>
           
            <ul>
                <Fade cascade >
                <li><NavLink to='./About' >About</NavLink></li>
                <li><NavLink to ='./Portfolio'>Portfolio</NavLink></li>
                <li><NavLink to ='./Contact'>Contact</NavLink ></li> 
                </Fade>
               
            </ul>

        </nav>
    )
}