import React from 'react'
import './about.scss';
const About = () => {
  return (
    <>
    <div className="main-block">
       <div className="about-text-block">
        <h1>All About Me! </h1>
        <p>Hi thanks for visiting my portfolio, I’m Chris a mixed media artist. I have decided to redo this portfolio page using the skills and tools I have learned from my 4 years of college and university. I work with a mixture of tools from photoshop to 3ds max to create art. Over the course of my life, I have always had a deep love for art and mostly worked with paper and pencil to draw and create comics. It wasn’t until my 20’s I discovered photoshop and my eyes were opened to a world of possibilities. College showed me how much I can push my self with my art and I started to dabble in 3d rendering and animation as well as photomanipulation.</p>
        <div className="buttons">
            <button className="btn-portfolio">
                <a href="/portfolio">Portfolio</a>                
            </button>
            <button className="btn-contact">
                <a href="/contact">Contact</a>                
            </button>
        </div>
       </div>
       <div className="img-block">
            
       </div>

    </div>
    </>
  )
}

export default About