import './home.scss';
import IMG from '../../assets/images/posteffects4.png'
export default function Home() {
    return (
        <>
         <div className='block'>
           <img src={IMG} alt="forest" className='image-block' loading='lazy' />
        </div>
        
        </>
       
    )

}