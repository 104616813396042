import React from "react";

import "./portfolio.scss";
import {Fade} from "react-awesome-reveal";
import IMG1 from "../../assets/images/ashoka.jpg";
import IMG2 from "../../assets/images/batman.jpg";
import IMG3 from "../../assets/images/batmanTwo.jpg";
import IMG4 from "../../assets/images/blackcat.jpg";
import IMG5 from "../../assets/images/cavern.jpg";
import IMG6 from "../../assets/images/cyberCity.png";
import IMG7 from "../../assets/images/dareDevil.jpg";
import IMG8 from "../../assets/images/darthVader.jpg";
import IMG9 from "../../assets/images/donatello.jpg";
import IMG10 from "../../assets/images/fractalTiger.jpg";
import IMG11 from "../../assets/images/gonkAndMousedroids.jpg";
import IMG12 from "../../assets/images/grass.jpg";
import IMG13 from "../../assets/images/harleyAndJoker.jpg";
import IMG14 from "../../assets/images/kaang.jpg";
import IMG15 from "../../assets/images/leia.jpg";
import IMG16 from "../../assets/images/leonardo.jpg";
import IMG17 from "../../assets/images/michelangelo.jpg";
import IMG18 from "../../assets/images/obiwan.jpg";
import IMG19 from "../../assets/images/outdoorScene.jpg";
import IMG20 from "../../assets/images/posteffects4.png";
import IMG21 from "../../assets/images/rainyalley.png";
import IMG22 from "../../assets/images/rapheal.jpg";
import IMG23 from "../../assets/images/red.jpg";
import IMG24 from "../../assets/images/rooftop.png";
import IMG25 from "../../assets/images/shackLight.png";
import IMG26 from "../../assets/images/slaveLeia.jpg";
import IMG27 from "../../assets/images/snowScene.jpg";
import IMG28 from "../../assets/images/underground.png";

import { useState } from "react";

// import data from './data.json'; 
const images = [IMG1, IMG2, IMG3, IMG4, IMG5, IMG6, IMG7, IMG8, IMG9, IMG10, IMG11, IMG12, IMG13, IMG14, IMG15, IMG16, IMG17, IMG18, IMG19, IMG20, IMG21, IMG22, IMG23, IMG24, IMG25, IMG26, IMG27, IMG28];



//MAIN APP COMPONENT
function App() {
  return (
    <>
     <h1 className="header">Portfolio</h1>
     <div className="portfolio">
     <Fade cascade damping={0.15} >
      <ImageGallery />
     </Fade>

    </div>
    </>
   
    
  );
}


//MAIN LIGHTBOX
//Holds Images Cards and Lightbox
//this is where all of our logic will live
function ImageGallery() {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  
  //looping through our images array to create img elements
  const imageCards = images.map((image) => (
    <img className="image-card" onClick={() => showImage(image)} src={image} />
  ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };
  

  return (
    <>
      <div>{imageCards}</div>
      
      {
        lightboxDisplay ? 
        <div id="lightbox" onClick={hideLightBox}>
          <button onClick={showPrev}>⭠</button>
          <img id="lightbox-img" src={imageToShow}></img>
          <button onClick={showNext}>⭢</button>
        </div>
       : ""
      }
    </>
  );
}

export default App;
