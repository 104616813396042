import "./footer.scss";
import { FaInstagram, FaArtstation, FaDeviantart  } from "react-icons/fa";

export default function Footer() {
  return (
    <footer>
      
      <div className="socials">
        <ul>
          <li>
            <a href="https://www.instagram.com/">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://www.artstation.com/">
              <FaArtstation />
            </a>
          </li>
          <li>
            <a href="https://www.deviantart.com/">
              <FaDeviantart />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
