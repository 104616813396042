import Navbar from "./components/NavBar/Navbar";
import {Route, Routes} from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import AnimatedCursor from "react-animated-cursor";

function App() {
  return (
    <>
    <AnimatedCursor />
    <Navbar />
    <Routes>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
